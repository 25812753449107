import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import BodyClassName from 'react-body-classname';

import DefaultLayoutComponent from '../../modules/layouts/DefaultLayoutComponent';
import BackgroundVideo from '../../components/Homepage/BackgroundVideo';

const TheGreatControversy = (props) => {
  const {
    data: {
      bgIndex,

      backgroundVideos,
    },
  } = props;

  return (
    <BodyClassName className="body-light page-gc">
      <DefaultLayoutComponent
        title="Lineage Journey - Identity, Purpose, Mission"
        description="Lineage is a multi-faceted educational tool designed to help you understand your spiritual heritage and explore the links between the past, present and future."
        // seo={wpPage.seo}
      >
        <div className="site-main">
          <BackgroundImage
            Tag="section"
            className="page-banner bgImage d-flex align-items-center"
            fluid={bgIndex.childImageSharp.fluid}
            preserveStackingContext
          >
            <BackgroundVideo videos={backgroundVideos.files.slice(1)} />
            <div className="overlay">
              <div className="wrapper wrapper-lg pd-x-sm pb-title-wrapper">
                <div className="pb-container pd-x-sm">
                  <h1 className="coming-soon-text">coming soon</h1>

                  <h1 className="pb-title">The Great Controversy Book</h1>
                  <h4 className="pb-subtitle">
                    Stay tuned for more historical facts, resources and content related to the Great Controversy Book.
                  </h4>

                  <Link className="btn btn-sm btn-primary-ii btn-timeline" to="/">
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </BackgroundImage>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};
export const query = graphql`
  {
    backgroundVideos: allS3Object {
      files: nodes {
        url
        Key
        Size
      }
    }
    bgIndex: file(relativePath: { eq: "season-3.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default TheGreatControversy;
